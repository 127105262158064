import { useState } from 'react';
import '../../css/signinpage.css';
import PasswordReset from './PasswordReset';

const SignInPage = ({ setIsLoggedIn, setUserInfo }) => {

    const [errorMessage, setErrorMessage] = useState("");
    const [showPassReset, setPassReset] = useState(false);
    const [showForgotFirst, setForgotFirst] = useState("");

    function passwordReset(val){
        setForgotFirst(val)
        setPassReset(true);
    }

    function handleSubmit(e){
        e.preventDefault();

        var dataSend =
            "user_id=" +
            document.querySelector(".email").value +
            "&" +
            "password=" +
            document.querySelector(".pass").value;

        const xhr = new XMLHttpRequest();

        xhr.onload = function () {
            if(this.responseText !== "Please enter username and password." && this.responseText !== "Incorrect password. Please try again." && this.responseText !== "Username not found."){
                const res = JSON.parse(this.responseText);
                setUserInfo({
                    id: res.id,
                    user_id: res.user_id,
                    user_name: res.user_name,
                    json_file: res.json_file,
                    design_template: res.design_template,
                    signup_path: res.signup_path
                });
                setIsLoggedIn(true);
            }else{
                setErrorMessage(this.responseText);
                setTimeout(() => {
                    setErrorMessage("");
                }, 2000);
            }
        };

        xhr.open("POST", "../loginusersystem/login.php");
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.send(dataSend);
    }

    return (
        <div className="sign-in-container">
            <PasswordReset showPassReset={showPassReset} setPassReset={setPassReset} showForgotFirst={showForgotFirst}/>
            <div className="sign-in-popup">
                <h1>Welcome!</h1>
                <h4>Please sign in.</h4>
                <div className="form-sign-in">

                    <div className="form-field">
                        <label>Email :</label>
                        <input type="text" className="email"/>
                    </div>
                    <div className="form-field">
                        <label>Password :</label>
                        <input type="password" className="pass"/>
                    </div>

                    <button onClick={handleSubmit}>Sign in</button>
                </div>
                <p className="error-message">{errorMessage}</p>
                <div className="forgot-first">
                    <button className="forgot-pass" onClick={() => passwordReset("forgot")}>Forgot password?</button>
                    <button className="forgot-pass" onClick={() => passwordReset("first")}>First time users?</button>
                </div>
            </div>
        </div>
    );
}
 
export default SignInPage;