const D1_HEADER_DATA = ({ appInfo, updateItem, updateTextSize }) => {

    function textChanged() {
    }

    const bgImage = {
        backgroundImage: `url(${appInfo.Image})`,
        backgroundPosition: 'right',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <div className="headersec">
            <div className="header headerimg">
                <div className="main" style={bgImage}>
                    <div className="main-content">
                        <h1 className="ht ht1" style={{fontSize: `${Math.floor(468 / appInfo.HeaderText1Size)}px`}}>{appInfo.HeaderText1}</h1>
                        <h1 className="ht ht2" style={{fontSize: `${Math.floor(468 / appInfo.HeaderText2Size)}px`}}>{appInfo.HeaderText2}</h1>
                        <h1 className="ht ht3" style={{fontSize: `${Math.floor(468 / appInfo.HeaderText3Size)}px`}}>{appInfo.HeaderText3}</h1>
                        <button className="header-btn">{appInfo.ButtonText}</button>
                    </div>
                </div>
            </div>
            <div className="add-edit-container headeredit">
                <div className="dd-top">
                    <h3>Header Image</h3>
                    <input type="text" value={appInfo.Image} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), 0, "Image", "Header")} />
                    
                    <h3>Header Text 1</h3>
                    <div className="header-edit">
                        <div className="header-edit-left">
                            <input type="text" value={appInfo.HeaderText1} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), 0, "HeaderText1", "Header")} />
                        </div>
                        <div className="header-edit-right">
                            <button onClick={e => updateTextSize(e.target.value, "HeaderText1Size", "Add")}>-</button>
                            <input type="text" value={appInfo.HeaderText1Size} onChange={textChanged}/>
                            <button onClick={e => updateTextSize(e.target.value, "HeaderText1Size", "Minus")}>+</button>
                        </div>
                    </div>
                    <h3>Header Text 2</h3>
                    <div className="header-edit">
                        <div className="header-edit-left">
                            <input type="text" value={appInfo.HeaderText2} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), 0, "HeaderText2", "Header")} />
                        </div>
                        <div className="header-edit-right">
                            <button onClick={e => updateTextSize(e.target.value, "HeaderText2Size", "Add")}>-</button>
                            <input type="text" value={appInfo.HeaderText2Size} onChange={textChanged}/>
                            <button onClick={e => updateTextSize(e.target.value, "HeaderText2Size", "Minus")}>+</button>
                        </div>
                    </div>
                    
                    <h3>Header Text 3</h3>
                    <div className="header-edit">
                        <div className="header-edit-left">
                            <input type="text" value={appInfo.HeaderText3} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), 0, "HeaderText3", "Header")} />
                        </div>
                        <div className="header-edit-right">
                            <button onClick={e => updateTextSize(e.target.value, "HeaderText3Size", "Add")}>-</button>
                            <input type="text" value={appInfo.HeaderText3Size} onChange={textChanged}/>
                            <button onClick={e => updateTextSize(e.target.value, "HeaderText3Size", "Minus")}>+</button>
                        </div>
                    </div>

                    <h3>Button Text</h3>
                    <input type="text" value={appInfo.ButtonText} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), 0, "ButtonText", "Header")} />
                    <h3>Button Link</h3>
                    <input type="text" value={appInfo.Link} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), "Link", 0, "Header")} />
                </div>
            </div>
        </div>
    );
}

 
export default D1_HEADER_DATA;