import { useState } from 'react';
import SignInPage from './SignInPage';
import D1_HOME_PAGE from './designone/D1_HOME_PAGE';


const CustomerArea = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState({
      id: "",
      user_id: "",
      user_name: "",
      json_file: "",
      design_template: "",
      signup_path: ""
    });

    function checkStatus() {
        const val = userInfo.design_template;
        switch (val) {
            case "one":
                return <D1_HOME_PAGE userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            isLoggedIn={isLoggedIn}
                            setIsLoggedIn={setIsLoggedIn}/>

            case "two":
                return "Two"
            default:
                break;
        }
    }

    return (
        <div>
            {isLoggedIn ? checkStatus() : <SignInPage setUserInfo={setUserInfo} setIsLoggedIn={setIsLoggedIn}/>}
        </div>
    );
}
 
export default CustomerArea;