import { useState } from 'react';
import D1_HEADER_DATA from './D1_HEADER_DATA';
// import D1_EVENT_DATA from './D1_EVENT_DATA';
// import D1_PRODUCT_DATA from './D1_PRODUCT_DATA';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Collapsible from 'react-collapsible';

const D1_APP_INFO_PAGE = ({ userInfo, appInfo, updateOrderProducts, updateOrderEvents, updateItem, updateTextSize, removeItem, addEvt, addPro }) => {

  const [showArchive, setArchive] = useState([]);


    function sendData() {
        const jsonSend = JSON.stringify([{
          "Header": appInfo[0].Header,
          "Events": [...appInfo[0].Events],
          "Products": [...appInfo[0].Products]
        }], null, 2);
    
        var dataSend =
        "json_file=" +
        userInfo.json_file +
        "&" +
        "json_data=" +
        jsonSend;
    
        const xhr = new XMLHttpRequest();
    
        xhr.onload = function () {
            if(this.responseText !== "Something went wrong. Please try again."){
                document.querySelector(".post-message").innerHTML = "Success!";
                setTimeout(() => {
                  document.querySelector(".post-message").innerHTML = "";
                }, 2000);
            }
        };
    
        xhr.open("POST", "../loginusersystem/postappinfo.php");
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.send(dataSend);
      }

    function archiveEvent(data, idx) {
      var archiveDataSend =
      "eventname=" +
      data.EventName +
      "&" +
      "image=" +
      data.Image +
      "&" +
      "time=" +
      data.Time +
      "&" +
      "location=" +
      data.Location +
      "&" +
      "discription=" +
      data.Discription +
      "&" +
      "link=" +
      data.Link +
      "&" +
      "route=" +
      data.Route;
  
      const xhr = new XMLHttpRequest();
  
      xhr.onload = function () {
          if(this.responseText === "Archived Complete"){
              alert("Set");
              removeItem(idx, "Events");
          }
      };
  
      xhr.open("POST", "../loginusersystem/postarchive.php");
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.send(archiveDataSend);
    }

    function sendForArchiveData() {
      var dataSend =
      "filepath=" +
      userInfo.json_file;

      const xhr = new XMLHttpRequest();

      xhr.onload = function () {
          if(this.responseText !== "Something went wrong. Please try again."){
              const data = JSON.parse(this.responseText);
              setArchive(data);
          }
      };

      xhr.open("POST", "../loginusersystem/getarchive.php");
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.send(dataSend);
    }

    function sendClearEvent() {
      addEvt(
        document.getElementById("eventNameInput").value.replace(/[&]/gi, ""),
        document.getElementById("eventImageInput").value.replace(/[&]/gi, ""),
        document.getElementById("eventTimeInput").value.replace(/[&]/gi, ""),
        document.getElementById("eventLocationInput").value.replace(/[&]/gi, ""),
        document.getElementById("eventDiscriptionInput").value.replace(/[&]/gi, ""),
        document.getElementById("eventLinkInput").value.replace(/[&]/gi, ""),
        document.getElementById("routeLinkInput").value.replace(/[&]/gi, "")
      );
      setTimeout(() => {
        document.getElementById("eventNameInput").value = "";
        document.getElementById("eventImageInput").value = "";
        document.getElementById("eventTimeInput").value = "";
        document.getElementById("eventLocationInput").value = "";
        document.getElementById("eventDiscriptionInput").value = "";
        document.getElementById("eventLinkInput").value = "";
        document.getElementById("routeLinkInput").value = "";
      }, 1000);
    }

    function sendClearProduct() {
      addPro(
        document.getElementById("productNameInput").value.replace(/[&]/gi, ""),
        document.getElementById("productLinkInput").value.replace(/[&]/gi, ""),
        document.getElementById("productImageInput").value.replace(/[&]/gi, ""),
        document.getElementById("productPriceInput").value.replace(/[&]/gi, ""),
        document.getElementById("productDiscriptionInput").value.replace(/[&]/gi, "")
      );
      setTimeout(() => {
        document.getElementById("productNameInput").value = "";
        document.getElementById("productLinkInput").value = "";
        document.getElementById("productImageInput").value = "";
        document.getElementById("productPriceInput").value = "";
        document.getElementById("productDiscriptionInput").value = "";
      }, 1000);
    }

    function handleOnDragEndEvents(result) {
      if (!result.destination) return;

      const eventItems = Array.from(appInfo[0].Events);
      const [reorderedItem] = eventItems.splice(result.source.index, 1);
      eventItems.splice(result.destination.index, 0, reorderedItem);
      updateOrderEvents(eventItems);
    }

    function handleOnDragEndProducts(result) {
      if (!result.destination) return;

      const productItems = Array.from(appInfo[0].Products);
      const [reorderedItem] = productItems.splice(result.source.index, 1);
      productItems.splice(result.destination.index, 0, reorderedItem);
      updateOrderProducts(productItems);
    }

    function addEventPlain(data) {
      addEvt(
        data.EventName,
        data.Image,
        data.Time,
        data.Location,
        data.Discription,
        data.Link,
        data.RouteLink
      );
    }

    return (
      <div>
            <div className="customer-container">
                <h1 className="section-name">Header Section</h1>
                {!appInfo[0].Header ? "Loading..." : <D1_HEADER_DATA appInfo={appInfo[0].Header} updateItem={updateItem} updateTextSize={updateTextSize}/>}
            </div>

            <div className="customer-container">
                <h1 className="section-name">Events Section</h1>
                <DragDropContext onDragEnd={handleOnDragEndEvents}>
                  <Droppable droppableId="event2">
                    { provided => 
                      <div className="eventDropable" {...provided.droppableProps} ref={provided.innerRef}>
                        {!appInfo[0].Events ? "Loading..." : appInfo[0].Events.map((info, idx) => (
                          <Draggable key={idx} draggableId={idx.toString()} index={idx}>
                            {(provided) => (
                              <div className="event" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                <Collapsible trigger={info.EventName} transitionTime={100} className="collapseDiv">
                                  <div className="eventinner">
                                    <div className="image-div">
                                        <img src={info.Image} alt={info.Image} />
                                    </div>
                                    <div className="details-div">
                                        <div className="dd-top">
                                            <h3>Event Name (Just for ordering purposes)</h3>
                                            <input type="Text" value={info.EventName} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "EventName", "Events")} />
                        
                                            <h3>Event Image</h3>
                                            <input type="Text" value={info.Image} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Image", "Events")} />
                        
                                            <h3>Event Time</h3>
                                            <input type="Text" value={info.Time} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Time", "Events")} />
                        
                                            <h3>Event Location</h3>
                                            <input type="Text" value={info.Location} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Location", "Events")} />
                        
                                            <h3>Event Discription</h3>
                                            <textarea type="Text" value={info.Discription} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Discription", "Events")} />
                        
                                            <h3>Event Link</h3>
                                            <input type="Text" value={info.Link} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Link", "Events")} />

                                            <h3>Route Link</h3>
                                            <input type="Text" value={info.RouteLink} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Route", "Events")} />
                        
                                        </div>
                                        <div className="dd-bottom">
                                          <button style={{marginRight: 10, backgroundColor: "blue"}} onClick={() => archiveEvent(info, idx)}>Archive</button>
                                          <button onClick={() => removeItem(idx, "Events")}>Remove</button>
                                        </div>
                                    </div>
                                  </div>
                                </Collapsible>

                              </div>

                              // <D1_EVENT_DATA info={info} idx={idx} key={idx} removeItem={removeItem} updateItem={updateItem} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} />
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                      </div>
                    }
                  </Droppable>

                </DragDropContext>

                
                <div className="add-edit-container">
                  <div className="dd-top">
                      <h1>Add Event From Archive</h1>
                      <div style={{marginTop: 20}}>
                        {showArchive.length === 0 ? "" : showArchive.map((info, idx) => (
                            <div style={{display: "flex", marginRight: 10, marginLeft: 10, marginBottom: 20}}>
                              <div style={{display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: '#d2b48c', padding: 10, borderRadius: 20}}>
                                <div style={{flexBasis: 1}}>
                                  <img style={{width: '220px', height: '250px'}} src={info.Image} alt={info.Image} />
                                </div>

                                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingLeft: 20, paddingTop: 10}}>
                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <h3>Event Name</h3>
                                    <p>{info.EventName}</p>
                                    <h3>Event Time</h3>
                                    <p>{info.Time}</p>
                                    <h3>Event Location</h3>
                                    <p>{info.Location}</p>
                                    <h3>Event Discription</h3>
                                    <p style={{textAlign: 'left'}}>{info.Discription}</p>
                                  <div className="dd-bottom" style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
                                    <button onClick={() => removeItem(idx, "Events")}>Remove</button> {/*  Remove still needs setup */}
                                    <button className="archiveBtn2" style={{marginLeft: 10}} onClick={() => addEventPlain(info)}>Add To Events</button>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        ))}
                      </div>
                      <div className="dd-bottom">
                        <button className="archiveBtn2" onClick={() => sendForArchiveData()}>Get Archive</button>
                      </div>
                  </div>
                </div>

                <div className="add-edit-container">
                  <div className="dd-top">
                      <h1>Add A New Event</h1>
                      <h3>Name (Just for ordering purposes)</h3>
                      <input type="text" placeholder="event name" id="eventNameInput" />
                      <h3>Image</h3>
                      <input type="text" placeholder="event image" id="eventImageInput" />
                      {/* <h3>Date</h3>
                      <input type="text" placeholder="event date" id="eventDateInput" /> */}
                      <h3>Time</h3>
                      <input type="text" placeholder="event time" id="eventTimeInput" />
                      <h3>Location</h3>
                      <input type="text" placeholder="event location" id="eventLocationInput" />
                      {/* <h3>Ride Type</h3>
                      <input type="text" placeholder="event ride type" id="eventBikeInput" /> */}
                      <h3>Discription</h3>
                      <textarea type="text" placeholder="event discription" id="eventDiscriptionInput" />
                      <h3>Link</h3>
                      <input type="text" placeholder="event link" id="eventLinkInput" />
                      <h3>Route Link</h3>
                      <input type="text" placeholder="route link" id="routeLinkInput" />
                      <div className="add-btn">
                      <button onClick={sendClearEvent}>Add Event</button>
                      </div>
                  </div>
                </div>
          </div>

          <div className="customer-container">
            <h1 className="section-name">Products Section</h1>
            <DragDropContext onDragEnd={handleOnDragEndProducts}>
                  <Droppable droppableId="product">
                    { provided => 
                      <div className="productDropable" {...provided.droppableProps} ref={provided.innerRef}>
                        {!appInfo[0].Products ? "Loading..." : appInfo[0].Products.map((info, idx) => (
                          <Draggable key={idx} draggableId={idx.toString()} index={idx}>
                            {(provided) => (
                              <div className="product" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                <Collapsible trigger={info.Name} transitionTime={100} className="collapseDiv">
                                  <div className="productinner">
                                    <div className="image-div">
                                        <img src={info.Image} alt={info.Image} />
                                    </div>
                                    <div className="details-div">
                                        <div className="dd-top">
                                            <h3>Product Name</h3>
                                            <input type="Text" value={info.Name} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Name", "Products")} />
                                            <h3>Product Link</h3>
                                            <input type="Text" value={info.Link} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Link", "Products")} />
                                            <h3>Product Image</h3>
                                            <input type="Text" value={info.Image} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Image", "Products")} />
                                            <h3>Product Price</h3>
                                            <input type="Text" value={info.Price} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Price", "Products")} />
                                            <h3>Product Discription</h3>
                                            <textarea type="Text" value={info.Discription} onChange={e => updateItem(e.target.value.replace(/[&]/gi, ""), idx, "Discription", "Products")} />
                                        </div>
                                        <div className="dd-bottom">
                                            <button onClick={() => removeItem(idx, "Products")}> Remove</button>
                                        </div>
                                    </div>
                                  </div>
                                </Collapsible>
                              </div>
                              // <D1_EVENT_DATA info={info} idx={idx} key={idx} removeItem={removeItem} updateItem={updateItem} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} />
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    }
                  </Droppable>
                </DragDropContext>
           
            {/* {!appInfo[0].Products ? "Loading..." : appInfo[0].Products.map((info, idx) => (
              <D1_PRODUCT_DATA info={info} idx={idx} key={idx} removeItem={removeItem} updateItem={updateItem} />
            ))} */}

            <div className="add-edit-container">
              <div className="dd-top">
                <h1>Add A New Product</h1>
                <h3>Name</h3>
                <input type="text" placeholder="product name" id="productNameInput" />
                <h3>Link</h3>
                <input type="text" placeholder="product link" id="productLinkInput" />
                <h3>Image</h3>
                <input type="text" placeholder="product image" id="productImageInput" />
                <h3>Price</h3>
                <input type="text" placeholder="product price" id="productPriceInput" />
                <h3>Discription</h3>
                <textarea type="text" placeholder="product discription" id="productDiscriptionInput" />
                <div className="add-btn">
                  <button onClick={sendClearProduct}>Add Product</button>
                </div>
              </div>
            </div>
          </div>

          <p className="post-message"></p>
          <button className="send-new-list-btn" onClick={sendData}>Send Data</button>
        </div>
    );
}
 
export default D1_APP_INFO_PAGE;