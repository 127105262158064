import exportFromJSON from 'export-from-json';
import { useState } from 'react';

const D1_Notifications = ({ userInfo }) => {

    const [showSearchDate, setSearchDate] = useState([]);
    const [displayDate, setDisplayDate] = useState("");

    function getData() {
        const searchDate = document.getElementById("datePickerSearch").value;

        setDisplayDate("Searching...");
        var dataSend =
        "signup_path=" +
        `${userInfo.signup_path + searchDate}.json`;

        const xhr = new XMLHttpRequest();
    
        xhr.onload = function () {
            if(this.responseText !== "Something went wrong. Please try again." && this.responseText !== "No File"){
                try {
                    const res = JSON.parse(this.responseText);
                    setSearchDate([...res]);
                    setDisplayDate(`Signups for ${searchDate}`);
                } catch (error) {
                    setSearchDate([]);
                }
            }else{
                setSearchDate([]);
                setDisplayDate("Doesn't look to be any signups for this day.");
            }
        };
    
        xhr.open("POST", "../loginusersystem/getsignupinfo.php");
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.send(dataSend);
    }

    function convertToExcel() {
        exportFromJSON({ data: showSearchDate, fileName: 'data', exportType: exportFromJSON.types.csv });
    }

    return (
        <div className="customer-sign-up-page">
            <div className="customer-container">
                <h1 className="section-name">Customer Notification Manager</h1>
                <div className="date-picker">
                    <input type="date" id="datePickerSearch" />
                    <button onClick={getData}>Search</button>
                </div>
                <div className="data-viewer">
                    <h2>{displayDate}</h2>
                    {!showSearchDate ? "" : showSearchDate.map((customer, idx) => (
                        <div className="customer-event-area" key={idx}>
                            <p>{idx + 1}</p>
                            <p>{customer.CustomersName}</p>
                            <p>{customer.PhoneNumber}</p>
                            <p>{customer.EventName}</p>
                        </div>
                    ))}
                    <button onClick={convertToExcel}>Convert to excel</button>
                </div>
            </div>
        </div>
    );
}

export default D1_Notifications;