import React, { useState } from 'react';
import { motion as FramerMotion } from "framer-motion";
import '../../css/signinpage.css';

const backdrop = {
    visable: {
        opacity: 1
    },
    hidden: {
        opacity: 0
    }
}

const modal = {
    hidden:{
        opacity: 0,
        y: -"100vh"
    },
    visable:{
        opacity: 1,
        y: "25vh",
        transition: {
            delay: 0.5
        }
    }
}

const PasswordReset = ({ showPassReset, setPassReset , showForgotFirst }) => {

    const [sentForReset, setSentForReset] = useState(false);
    const [passMessage, setPassMessage] = useState("");

    function sentForNewPass(){
        const value = document.getElementById("email-pass-reset").value.trim()
        if(value){
            setSentForReset(true);
    
            var dataSend =
                "user_id=" + document.getElementById("email-pass-reset").value + "&" +
                "email_type=" + showForgotFirst;
    
            const xhr = new XMLHttpRequest();
    
            xhr.onload = function () {
                console.log(this.responseText);
            };
    
            xhr.open("POST", "../loginusersystem/sendresetcode.php");
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            xhr.send(dataSend);
        }else{
            setPassMessage("Please enter a email and try again.");
            setTimeout(() => {
                setPassMessage("");
            }, 2000);
        }
    }

    function cancelClicked(){
        setPassReset(false);
        setSentForReset(false);
    }

    function confirmResetPass(){
        const resetCode = document.getElementById("verification-code").value.trim();
        const newPassword = document.getElementById("new-pass").value.trim();

        if(resetCode && newPassword){
            var dataSend = 
                "user_id=" + document.getElementById("email-pass-reset").value + "&" + 
                "user_reset_code=" + document.getElementById("verification-code").value + "&" + 
                "user_new_pass=" + document.getElementById("new-pass").value;
                
            const xhr = new XMLHttpRequest();
    
            xhr.onload = function () {
                if(this.responseText === "Password updated successfully!"){
                    setPassMessage(this.responseText);
                }else{
                    setPassMessage(this.responseText);
                }
    
                setTimeout(() => {
                    setPassMessage("");
                    setSentForReset(false);
                    setPassReset(false);
                }, 2000);
            };
    
            xhr.open("POST", "../loginusersystem/updatepassword.php");
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            xhr.send(dataSend);
        }else{
            setPassMessage("Please enter the reset code and new password.");
            setTimeout(() => {
                setPassMessage("");
            }, 2000);
        }
    }

    return(
        <div>
            {showPassReset && (
                <FramerMotion.div className="backdrop" variants={backdrop} initial="hidden" animate="visable" exit="hidden">
                    <FramerMotion.div className="modal" variants={modal} initial="hidden" animate="visable">
                        <h4>{showForgotFirst === "forgot" ? "Forgot your password? No worries. Let's fix this for you." : "Welcome! Let's create you a unique password."}</h4>
                        <p>Step 1</p>
                        <input type="text" id="email-pass-reset" className="reset-inputs" placeholder="Please enter your registered email here."/>
                        {sentForReset ? "" : <div className="reset-btns">
                            <button className="pass-reset-btns" onClick={sentForNewPass}>Submit</button>
                            <button className="pass-reset-btns" onClick={cancelClicked}>Cancel</button>
                        </div>}
                        {!sentForReset ? "" : <div className="steps">
                                <p>Step 2</p>
                                <input type="text" id="verification-code" className="reset-inputs" placeholder="Enter the reset code here sent to your email above."/>
                                <input type="text" id="new-pass" className="reset-inputs" placeholder="Enter Your New Password Here."/>
                                <div>
                                    <button className="pass-reset-btns" onClick={confirmResetPass}>Submit</button>
                                    <button className="pass-reset-btns" onClick={cancelClicked}>Cancel</button>
                                </div>
                            </div>}
                        <p id="pass-message">{passMessage === "" ? "" : passMessage}</p>
                    </FramerMotion.div>
                </FramerMotion.div>
            )}
        </div>
    )
}

export default PasswordReset;