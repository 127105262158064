import { useState, useEffect } from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import D1_APP_INFO_PAGE from './D1_APP_INFO_PAGE';
import D1_NOTIFICATIONS from './D1_NOTIFICATIONS';
import '../../../css/customerloggedin.css';


const D1_HonmePage = ({ userInfo, setUserInfo, isLoggedIn, setIsLoggedIn }) => {

  window.history.pushState({"html":"","pageTitle":""},"", "/customerarea/d1appinfopage");
  // window.history.pushState({"html":"","pageTitle":""},"", "http://localhost:3000/customerarea/d1appinfopage");

  const [appInfo, setAppInfo] = useState([{
    Header: {},
    Events: [],
    Products: []
  }]);

  const [showTempObj, setTempObj] = useState({});

  useEffect(() => {

    var dataSend =
    "filepath=" +
    userInfo.json_file;

    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
        if(this.responseText !== "Something went wrong. Please try again."){
            const data = JSON.parse(this.responseText);
            setAppInfo(data);
        }
    };

    xhr.open("POST", "../loginusersystem/getappinfo.php");
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.send(dataSend);
  }, [userInfo.json_file]);

  function addEvt(name, image, time, loc, discription, link, route) {
    const newAppInfo = [...appInfo];
    newAppInfo[0]["Events"].push({
      "EventName": name,
      "Image": image,
      "Time": time,
      "Location": loc,
      "Discription": discription,
      "Link" : link,
      "Route" : route
    });
    setAppInfo(newAppInfo);
  }

  function addPro(name, link, image, price, discription) {
    const newAppInfo = [...appInfo];
    newAppInfo[0]["Products"].push({
      "Name": name,
      "Link": link,
      "Image": image,
      "Price": price,
      "Discription": discription
    });
    setAppInfo(newAppInfo);
  }

  function removeItem(idx, evtType) {
    const newAppInfo = [...appInfo];
    newAppInfo[0][evtType].splice(idx, 1);
    setAppInfo(newAppInfo);
  }

  // function archiveItem(idx, evtType) {
  //   // alert(appInfo[0][evtType][idx].EventName);
  //   setTempObj(appInfo[0][evtType][idx]);
  // }

  function updateItem(e, idx, inputEdit, evtType) {
    const newAppInfo = [...appInfo];
    evtType === "Header" ? newAppInfo[0][evtType][inputEdit] = e : newAppInfo[0][evtType][idx][inputEdit] = e;
    setAppInfo(newAppInfo);
  }

  function updateOrderEvents(items) {
    const newAppInfo = [...appInfo];
    newAppInfo[0].Events = items;
    setAppInfo(newAppInfo);
    console.log(appInfo);
  }

  function updateOrderProducts(items) {
    const newAppInfo = [...appInfo];
    newAppInfo[0].Products = items;
    setAppInfo(newAppInfo);
    console.log(appInfo);
  }

  function updateTextSize(e, evtName, addsub) {
    const newAppInfo = [...appInfo];
    addsub === "Add" ?  newAppInfo[0].Header[evtName] = (newAppInfo[0].Header[evtName] + 1) : newAppInfo[0].Header[evtName] = (newAppInfo[0].Header[evtName] - 1);
    setAppInfo(newAppInfo);
  }
    
  function signOut(e) {
    e.preventDefault();
    setUserInfo({
        id: "",
        user_id: "",
        user_name: "",
        json_file: "",
        design_template: "one"
    });
    setIsLoggedIn(false);
    window.history.pushState({"html":"","pageTitle":""},"", "https://www.soloapplab.com");
}

  return (
    <div className="logged-in-page">
      <Router>

        <div className="app-info">
          <div className="user-navbar">
            <div className="user-navbar-options">
              <Link to="/customerarea/d1appinfopage"><button>App Info</button></Link>
              <Link to="/customerarea/d1notifications"><button>Customer Sign-up</button></Link>
            </div>
            <div className="welcome-user">
              <h4>Welcome {userInfo.user_name}</h4>
              <button onClick={signOut}>{isLoggedIn ? "Log out" : "Log In"}</button>
            </div>
          </div>

          <Switch>
            <Route path="/customerarea/d1appinfopage">
              <D1_APP_INFO_PAGE userInfo={userInfo} appInfo={appInfo} updateOrderProducts={updateOrderProducts} updateOrderEvents={updateOrderEvents} updateItem={updateItem} removeItem={removeItem} addEvt={addEvt} addPro={addPro} updateTextSize={updateTextSize}/>
            </Route>
            <Route path="/customerarea/d1notifications">
              <D1_NOTIFICATIONS userInfo={userInfo}/>
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default D1_HonmePage;