import { BrowserRouter as Router, Link, Switch, Route} from "react-router-dom";
import DesignRouter from "./components/customerarea/DesignRouter";
import "./css/home.css";
import "./css/about.css";
import "./css/contact.css";
import "./css/quote.css";
import "./css/portfolio.css";
import './App.css';
import './css/nav.css';

function App() {

  const headerContainer ={
    minHeight: window.screen.height - 210
  };

  function sendContact(e) {
    var dataSend =
        "name=" +
        document.getElementById("contact-name").value + "&" +
        "email=" +
        document.getElementById("contact-email").value + "&" +
        "message=" + 
        document.getElementById("contact-message").value;
    
        const xhr = new XMLHttpRequest();
    
        xhr.onload = function () {
          if(this.responseText !== "Something went wrong. Please try again."){
            document.getElementById("contact-name").value = "";
            document.getElementById("contact-email").value = "";
            document.getElementById("contact-message").value = "";
            document.querySelector(".contact-quote-message").innerHTML = this.responseText;
            setTimeout(() => {
              document.querySelector(".contact-quote-message").innerHTML = "";
            }, 2000);
          }else{
            document.querySelector(".contact-quote-message").innerHTML = this.responseText;
            setTimeout(() => {
              document.querySelector(".contact-quote-message").innerHTML = "";
            }, 2000);
          }
        };
    
        xhr.open("POST", "https://www.soloapplab.com/loginusersystem/sendcontact.php");
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.send(dataSend);
  }

  function sendQuote() {
    var dataSend =
        "name=" +
        document.getElementById("quote-name").value + "&" +
        "email=" +
        document.getElementById("quote-email").value + "&" +
        "message=" + 
        document.getElementById("quote-message").value + "&" +
        "platform=" + 
        document.getElementById("quote-platform-selection").value;;
        
        const xhr = new XMLHttpRequest();
    
        xhr.onload = function () {
          if(this.responseText !== "Something went wrong. Please try again."){
            document.getElementById("quote-name").value = "";
            document.getElementById("quote-email").value = "";
            document.getElementById("quote-message").value = "";
            document.querySelector(".contact-quote-message").innerHTML = this.responseText;
            setTimeout(() => {
              document.querySelector(".contact-quote-message").innerHTML = "";
            }, 2000);
          }else{
            document.querySelector(".contact-quote-message").innerHTML = this.responseText;
            setTimeout(() => {
              document.querySelector(".contact-quote-message").innerHTML = "";
            }, 2000);
          }
        };
    
        xhr.open("POST", "https://www.soloapplab.com/loginusersystem/sendquote.php");
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.send(dataSend);
  }

  return (
    <Router>
      <div className="App">
        <div className="bg-image"></div>
        <nav className="nav">
          <Link to="/" className="solo-app-lab">Solo App Lab</Link>
          <ul>
            <li>
              <Link to="/" className="nav-links">Home</Link>
            </li>
            <li>
              <Link to="/portfolio" className="nav-links">Portfolio</Link>
            </li>
            <li>
              <Link to="/about" className="nav-links">About</Link>
            </li>
            <li>
              <Link to="/contact" className="nav-links">Contact</Link>
            </li>
            <li>
              <Link to="/signin" className="nav-links">Customer Login</Link>
            </li>
          </ul>
        </nav>

        <Switch>
          <Route path="/signin">
            <DesignRouter />
          </Route>
          <Route path="/about">
            <div className="about-container">
                <h1 id="about-page-h1">About</h1>
                <div className="about-section">
                  <h2>Who Am I?</h2>
                  <p>My name is Michael and I am a software developer.</p>
                  <p>I’ve created multiple apps and sites. Some of which can be seen on this site's portfolio page. I’ve been programming for over 5 years and love every part of it.</p>
                  <p>Programming languages / frameworks</p>
                  <div className="about-langs">
                    <ul>
                      <li>- React JS</li>
                      <li>- React Native</li>
                      <li>- Xamarin.Forms</li>
                      <li>- C#</li>
                      <li>- .Net</li>
                      <li>- Xaml</li>
                    </ul>
                    <ul>
                      <li>- php</li>
                      <li>- JavaScript</li>
                      <li>- Json</li>
                      <li>- HTML</li>
                      <li>- CSS</li>
                    </ul>
                  </div>
                  <p>If you would like me to help bring your idea to life please contact me using the “Get A Free Estimate” form.</p>
                  <div className="about-section-btns">
                    <Link to="/quote"><button>Get A Free Estimate</button></Link>
                    <Link to="/contact"><button>Contact Me</button></Link>
                  </div>
                </div>
            </div>
          </Route>
          <Route path="/portfolio">
          <div className="portfolio-container">
              <h1 id="portfolio-page-h1">Portfolio</h1>
              <div className="portfolio-section">
                <p>Here is some of my work. Please have a look and if you have any questions please feel free to contact me <Link to="/contact" id="contact-link">here</Link>.</p>
                <div className="portfolio-loop">
                  <h4>Toledo Zoo (concept)</h4>
                  <div className="grid-container">
                    <img className="grid-item" src="https://www.soloapplab.com/images/Zoo_1.jpg" alt="Toledo Zoo App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/Zoo_2.jpg" alt="Toledo Zoo App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/Zoo_3.jpg" alt="Toledo Zoo App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/Zoo_4.jpg" alt="Toledo Zoo App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/Zoo_5.jpg" alt="Toledo Zoo App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/Zoo_6.jpg" alt="Toledo Zoo App"/>
                  </div>
                </div>
                <div className="portfolio-loop">
                  <h4>Honda East Toledo</h4>
                  <div className="grid-container">
                    <img className="grid-item" src="https://www.soloapplab.com/images/HondaEast_1.jpg" alt="Honda East App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/HondaEast_2.jpg" alt="Honda East App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/HondaEast_3.jpg" alt="Honda East App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/HondaEast_4.jpg" alt="Honda East App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/HondaEast_5.jpg" alt="Honda East App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/HondaEast_6.jpg" alt="Honda East App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/HondaEast_7.jpg" alt="Honda East App"/>
                    <img className="grid-item" src="https://www.soloapplab.com/images/HondaEast_8.jpg" alt="Honda East App"/>
                  </div>
                </div>
              </div>
            </div>
          </Route>
          <Route path="/contact">
            <div className="contact-container">
              <h1 id="contact-page-h1">Contact</h1>
              <div className="contact-section">
                <h2>Let's talk!</h2>
                <h4>Fill out the form below and I'll get back to you as soon as possible.</h4>
                <div>
                  <input type="text" placeholder="Name" id="contact-name" required/>
                  <input type="email" placeholder="Email" id="contact-email" required/>
                  <textarea placeholder="Message" id="contact-message" cols="40" rows="5" required/>
                  <button id="contact-btn" onClick={sendQuote}>Submit</button>
                </div>
                <p className="contact-quote-message"></p>
              </div>
            </div>
          </Route>
          <Route path="/quote">
            <div className="quote-container">
              <h1 id="quote-page-h1">Quote</h1>
              <div className="quote-section">
                <h2>Let's get you a solution!</h2>
                <h4>Fill out the form below and I'll get back to you as soon as possible.</h4>
                <div>
                  <input type="text" placeholder="Name" id="quote-name" required/>
                  <input type="email" placeholder="Email" id="quote-email" required/>
                  <select id="quote-platform-selection" required>
                    <option value="Null">*Please choose a project</option>
                    <option value="Android">Android</option>
                    <option value="iOS">iOS</option>
                    <option value="Website">Website</option>
                    <option value="Android and Website">Android and Website</option>
                    <option value="iOS and Website">iOS and Website</option>
                    <option value="Android, iOS and Website">Android, iOS and Website</option>
                  </select>
                  <textarea placeholder="Description of your needs." id="quote-message" cols="40" rows="5" required/>
                  <button id="quote-btn" onClick={sendQuote}>Submit</button>
                </div>
                <p className="contact-quote-message"></p>
              </div>
            </div>
          </Route>
          <Route path="/">
            <div className="home-page-container">
              <div className="home-header-container" style={headerContainer}>
                  <div className="home-header-left">
                      <h3>Creating simple things for small businesses.</h3>
                      <h1>Get The Right App For Your Business</h1>
                      <p>Do you need a mobile app or website for your business? Then look no further! Contact me today for a free estimate and help your business grow in the digital market.</p>
                      <Link to="/quote"><button>Get A Free Estimate</button></Link>
                  </div>
                  <div className="home-header-right"></div>
              </div>
            </div>
          </Route>
        </Switch>
        <h4 className="app-footer">Copyright © 2021 Solo App Lab LLC</h4>
      </div>
    </Router>
  );
}

export default App;
